import React from 'react';
import { Form, Input, Checkbox, Button } from '@arco-design/web-react';

const FormItem = Form.Item;

function App() {
    const [layout, setLayout] = React.useState('horizontal');
    return (
        <Form
            style={
                layout === 'inline'
                    ? {
                        width: '100%',
                    }
                    : {
                        maxWidth: 600,
                    }
            }
            autoComplete='off'
            layout={layout}
        >
            <FormItem name="uid" label='用户名' field='username' tooltip={<div>用户名是必填项</div>} rules={[{ required: true }]}>
                <Input style={{ width: 270 }} placeholder='请输入用户名' />
            </FormItem>
            <FormItem name="password" label='密码' field='password' tooltip={<div>密码是必填项</div>} rules={[{ required: true }]}>
                <Input style={{ width: 270 }} placeholder='请输入密码' type="password" />
            </FormItem>
            <FormItem
                wrapperCol={
                    layout === 'horizontal'
                        ? {
                            offset: 5,
                        }
                        : {}
                }
            >
                <Checkbox>我已阅读手册</Checkbox>
            </FormItem>
            <FormItem
                wrapperCol={
                    layout === 'horizontal'
                        ? {
                            offset: 5,
                        }
                        : {}
                }
            >
                <Button id="btn" type='primary' htmlType='submit'>
                    登录
                </Button>
            </FormItem>
        </Form>
    );
}

export default App;