import React from 'react';
import { Button, Message } from '@arco-design/web-react';
import '@arco-design/web-react/dist/css/arco.css';
import PageHeader from './components/PageHeader';
import Alert from './components/Alert';
import Form from "./components/Form";
import Col from "./components/Col";

function App() {
  const handleClick = () => {
    Message.success('You clicked the button!');
  };

  return (
      <>
        <div style={{padding: 20}}>
          <PageHeader/>
        </div>
        <div style={{width: '59%', margin: '0 auto'}}>
          <br/>
        <Alert/>
          <h2>登录教务系统</h2>
          <Col/>
        </div>
        </>
        );
        }

        export default App;