import { PageHeader, Message, Radio } from '@arco-design/web-react';

const App = () => {
    return (
            <PageHeader
                style={{ background: 'var(--color-bg-2)' }}
                title='铁门关职业技术学院'
                subTitle='统一身份认证'
                extra={
                    <div>
                        <Radio.Group mode='fill' type='button' defaultValue='small'>
                            <Radio value='small'>登录</Radio>
                            <Radio value='medium'>注册</Radio>
                        </Radio.Group>
                    </div>
                }
            />
    );
};

export default App;
