import React from 'react';
import { Grid } from '@arco-design/web-react';
import Form from "./Form";

const Row = Grid.Row;
const Col = Grid.Col;

function App() {
    return (
        <Row>
            <Col span={12}>
                <div style={{ padding: '20px' }}>
                    <img src="https://cer.imu.edu.cn/authserver/imuTheme/customStatic/web/images/left-bg.png" alt="" width="90%" />
                </div>
            </Col>
            <Col span={12}>
                <div style={{ padding: '20px' }}>
                    <Form/>
                </div>
            </Col>
        </Row>
    );
}

export default App;